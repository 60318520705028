import React from 'react'
import VisibilitySensor from 'react-visibility-sensor';
import './cclStats.scss';

const stats = [
    {
        id: 'inspired',
        stat: '100%',
        about: 'Would recommend CCL to a colleague'
    },
    {
        id: 'alumni',
        stat: '99%',
        about: 'Felt the lessons learned were relevant to their challenges they face as leaders'
    },
    {
        id: 'served',
        stat: '100%',
        about: 'Were overall satisfied with their CCL experience'
    }
];

const animateValue = (id, endString, end) => {
    let range = end - 0;
    let current = 0;
    let increment = end > 0 ? 1 : -1;
    let stepTime = Math.abs(Math.floor(1000 / range));
    let obj = document.getElementById(id);

    let timer = setInterval(function () {
        current += increment;
        obj.innerHTML = current + endString;
        if (current === end) {
            clearInterval(timer);
        }
    }, stepTime);
}

let counted = false;

const onVisible = isVisible => {
    if (isVisible && !counted) {
        animateValue('inspired', '%', 100);
        animateValue('alumni', '%', 99);
        animateValue('served', '%', 100);
        counted = true;
    }
}

const openLink = link => {
    if (link) {
        window.open(link, '_blank');
    }
}

const HealthcareStats = ({ isVisible }) => {

    return (
        <div className="ccl-stats">
            <div className="stats">
                <h2 className="title">
                    CCL creates results that matter. Here’s how our past healthcare sector participants feel:
                </h2>
                <VisibilitySensor onChange={onVisible}>
                    <div className="stats-content subpage">
                        {stats.map(stat => (
                            <div className={`ccl-stat ${stat.link ? 'pointer' : ''}`} key={stat.id} onClick={() => openLink(stat.link)}>
                                <h2 id={stat.id}>{stat.stat}</h2>
                                <h4 className={stat.link ? 'underlined' : ''}>{stat.about}</h4>
                            </div>
                        ))}
                    </div>
                </VisibilitySensor>
            </div>
        </div>
    );
};

export default HealthcareStats;
